<template>
    <div id="cps-courses">
        <h1>LPS Course Production System Quick Entry</h1>
        <p>Note: Cases are matched by Course and Start Term of Lecturer.  Lecturer Case info is only refreshed every 4 hours
            so there may be a delay in displaying the data.</p>
        <div class="auth" v-if="authenticated">
            <div class="md-layout md-gutter md-alignment-top-center cps-course-table">
                <div class="md-layout-item md-size-90">
                    <md-table v-model="searched" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
                              :md-sort-fn="itemSort" md-card md-fixed-header>
                        <md-table-toolbar>
                            <div class="md-toolbar-section-start">
                                <md-field>
                                    <label for="srsTerm">Term</label>
                                    <md-select v-model="srsTerm" id="srsTerm" name="srsTerm" @md-selected="changeTerm">
                                        <md-option v-for="option in termOptions" :key="option.value" :value="option.value">
                                            {{option.option}}
                                        </md-option>
                                    </md-select>
                                </md-field>
                            </div>
                            <md-field md-clearable class="md-toolbar-section-end">
                                <md-input placeholder="Search for text..." v-model="search"/>
                            </md-field>
                        </md-table-toolbar>

                        <md-table-empty-state v-if="!loaded"
                                              md-label="Loading"
                                              :md-description="`Loading CPS Courses`">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </md-table-empty-state>

                        <md-table-empty-state v-else md-label="No CPS Courses found">
                            <p v-if="search">
                                No CPS found for this query: {{search}} . Try a different search term.
                            </p>
                            <p v-else>
                                You do not have any Course Production Courses listed for your department.
                            </p>
                        </md-table-empty-state>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Course" md-sort-by="course">{{item.subjectArea}} {{item.courseNumber}}</md-table-cell>
                            <md-table-cell md-label="Term" >{{item.termCode.replace(/_/g, ' ')}}</md-table-cell>
                            <md-table-cell md-label="Instructor" md-sort-by="lastName">{{ item.lastName }}, {{item.firstName}}</md-table-cell>
                            <md-table-cell md-label="Sections" md-sort-by="numSections">{{ item.numSections}}</md-table-cell>
                            <md-table-cell md-label="Department/Unit" md-sort-by="orgDescr">
                                <span v-if="item.unitName">
                                    {{ item.unitName.replace("School of Arts and Sciences -", "") }}
                                </span>
                                <span v-else>
                                    [no match for subject area]
                                </span>
                            </md-table-cell>
                            <md-table-cell md-label="Case Matches*">
                                <span v-for="packet in item.lecturerMatches" v-bind:key="packet.packetId" class="case-match">
                                    <router-link :to="{ name: 'caseDetail', params: {caseId: packet.packetId } }">
                                        {{ packet.candidateName }}
                                        <i class="md-icon md-icon-font inline-header-icon md-theme-default">open_in_new</i>
                                    </router-link>
                                </span>
                            </md-table-cell>
                            <md-table-cell>
                                <md-button @click="selectCourse(item)" class="md-raised md-primary"> Kickoff Case </md-button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </div>
            <div id="create-new-warning-cps-course">
                <md-dialog-confirm
                  :md-active.sync="showCreateNewDialog"
                  md-title="Warning: You have an open case"
                  md-content="You currently have an incomplete case open. Would you like to delete that case and start a new one using this course/instructor?"
                  md-confirm-text="Clear & Create New"
                  md-cancel-text="Continue Working on Open Case"
                  @md-cancel="caseRouter()"
                  @md-confirm="caseRouter(true)" />
            </div>
            <div id="select-additional-instructor-courses">
                <md-dialog :md-active.sync="showSelectCoursesDialog" v-if="selectedCourses && selectedCourses.length > 0">
                    <md-dialog-title>Select Additional Courses </md-dialog-title>
                    <md-content id="select-additional-courses">
                        <p>This instructor is also listed as teaching the following courses in the same unit this term.</p>
                        <p>Would you like to add these courses to the case for {{selectedCourses[0].subjectArea}} {{selectedCourses[0].courseNumber}}?</p>
                        <li v-for="course in additionalInstructorCourses" v-bind:key="course.id">
                            {{course.subjectArea}} {{course.courseNumber}}
                        </li>
                    </md-content>
                    <md-dialog-actions>
                        <md-button class="md-raised md-primary" @click="handleSaveCase(true)">Add Additional Courses</md-button>
                        <md-button class="md-raised" @click="handleSaveCase(false)">Just {{selectedCourses[0].subjectArea}} {{selectedCourses[0].courseNumber}}</md-button>
                    </md-dialog-actions>

                </md-dialog>
            </div>
        </div>
    </div>
</template>
<script>
  import {LOAD_STATUS} from "../store/store-utils"

  const toLower = text => {
    return text.toString().toLowerCase();
  }

  const searchByText = (items, term) => {
    if (term) {
      return items.filter(item => toLower(JSON.stringify(item)).includes(toLower(term)));
    }
    return items;
  };


  //Get all terms for this year and the next year
  const getSrsTermOptions = () => {
    let terms = {'A': 'Spring', 'B': 'Summer', 'C': 'Fall'};
    let currentYear = new Date().getFullYear();
    let years = ['2015', currentYear.toString(), (currentYear + 1).toString()];
    let dropdownValues = [];
    for(let year of years) {
      for(let term in terms) {
        dropdownValues.push({value: year + term, option: year + ' ' + terms[term]});
      }
    }
    return dropdownValues;
  };


  //get default term
  const getDefaultSrsTerm = function(){
    let now = new Date();
    if(now.getMonth() === 1) {
      return now.getFullYear().toString() + 'A';
    }
    else if(now.getMonth() < 10) {
      return now.getFullYear().toString() + 'C';
    }
    return (now.getFullYear() + 1).toString() + 'A';
  };

  const customSort = (items, field, direction) => {
    return items.sort((a, b) => {
      let val1 = a[field] ? a[field] : "zzzz";
      let val2 = b[field] ? b[field] : "zzzz";
      if(field === "course") {
        val1 = a.subjectArea + a.courseNumber;
        val2 = b.subjectArea + b.courseNumber;
      }
      if(field === "orgDescr") {
        val1 = a.unitName ? a.unitName.replace('School of Arts and Sciences - ', "") : "zzzz";
        val2 = b.unitName ? b.unitName.replace('School of Arts and Sciences - ', "") : "zzzz";
      }

      if (direction === 'desc') {
        return val2.localeCompare(val1)
      }
      return val1.localeCompare(val2)
    })
  };

  export default {
    name: "CPSCourses.vue",
    created: function() {
      this.$store.dispatch('sasData/loadTermCPSCourses', getDefaultSrsTerm());
    },
    data: function()  {
      let defaultTerm = getDefaultSrsTerm();
      return {
        currentSort: "lastName",
        currentSortOrder: "asc",
        search: null,
        loginUrl:  process.env.VUE_APP_SIGNIN_URL,
        showCreateNewDialog: false,
        showSelectCoursesDialog: false,
        selectedCourses: null,
        srsTerm: defaultTerm,
        termOptions: getSrsTermOptions(),
        additionalInstructorCourses: []
      }
    },
    computed: {
      authenticated() {
        return this.$store.getters['user/authenticated']
      },
      searched: {
        get: function () {
          let courses = this.$store.getters['sasData/cpsCourses'](this.srsTerm).data;
          return customSort(searchByText(courses, this.search), this.currentSort, this.currentSortOrder);
        },
        set: function () {
          //do nothing
        }
      },
      loaded() {
        return this.$store.getters['sasData/cpsCourses'](this.srsTerm).loadStatus === LOAD_STATUS.LOADED;
      },
      loadStatus() {
        return this.$store.getters['sasData/cpsCourses'](this.srsTerm).loadStatus;
      }
    },
    methods: {
      itemSort(value) {
        return customSort(value, this.currentSort, this.currentSortOrder);
      },
      displayDate(date) {
        return date.substr(5,2) + "/" + date.substr(8,2) + "/" + date.substr(0,4);
      },
      selectCourse(cpsCourse) {
        this.selectedCourses = [cpsCourse];
        if(cpsCourse.pennId) {
          let courses = this.$store.getters['sasData/cpsInstructorTermCourses']({
            srsTerm: this.srsTerm,
            pennId: cpsCourse.pennId
          });
          this.additionalInstructorCourses = [];
          for(let course of courses) {
            if(cpsCourse.id !== course.id && cpsCourse.unitName === course.unitName) {
              this.additionalInstructorCourses.push(course);
            }
          }
          if(this.additionalInstructorCourses.length > 0) {
            this.showSelectCoursesDialog = true;
          }
          else {
            this.handleSaveCase(false);
          }
        }
        else {
          this.handleSaveCase(false)
        }
      },
      handleSaveCase(addAdditionalCourses) {
        this.showSelectCoursesDialog = false;
        if(addAdditionalCourses) {
          this.selectedCourses = this.selectedCourses.concat(this.additionalInstructorCourses);
        }
        if (localStorage.getItem('caseKickoffForm')){
          this.showCreateNewDialog = true;
        } else {
          this.showCreateNewDialog = false;
          this.caseRouter(true);
        }
      },
      changeTerm(srsTerm) {
        this.$store.dispatch('sasData/loadTermCPSCourses', srsTerm);
      },
      caseRouter(newCase) {
        if (newCase) {
            this.$store.dispatch('form/initiateFromCPS', this.selectedCourses, {root: true});
            this.$emit('newCaseRouter', false, "", true);
        } else {
            this.$emit('newCaseRouter');
        }
      }
    }
  }
</script>

<style >
    .cps-course-table .md-table-head {
        text-align: center;
    }
    .md-toolbar-section-start div.md-field {
        width: 50%;
    }
    #select-additional-courses{
        padding: 5px;
    }
    #cps-courses a {
        text-decoration: none;
    }
    #cps-courses a:hover {
        color: #0000FF;
        text-decoration: none;
    }
    #cps-courses .case-match {
        display: block;
    }

</style>
