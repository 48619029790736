<template>
    <div class="page-container" id="help">
        <md-app md-waterfall md-mode="fixed">
            <md-app-drawer md-permanent="full">
                <md-list>
                    <md-list-item>
                        <a href="#newcase"><span class="md-list-item-text">Create a New Case</span></a>
                    </md-list-item>
                    <md-list-item>
                        <a href="#restartcase"><span class="md-list-item-text">Restart a Saved Case</span></a>
                    </md-list-item>
                    <md-list-item>
                        <a href="#viewcase"><span class="md-list-item-text">View Case Status and Details</span></a>
                    </md-list-item>
                    <md-list-item>
                        <a href="#form"><span class="md-list-item-text">Filling out the Case Kickoff Form</span></a>
                    </md-list-item>
                    <md-list-item>
                        <a href="#interfolio"><span class="md-list-item-text">Interfolio Help</span></a>
                    </md-list-item>
                </md-list>
            </md-app-drawer>

            <md-app-content>
                <md-app-toolbar  id="newcase" class="md-primary">
                    <span class="md-title">Create a New Case</span>
                </md-app-toolbar>
                <div class="md-layout md-alignment-top-space-between">
                    <div class="md-layout-item md-size-70">
                        <p>There are several ways to kickoff a new case:
                            <ol>
                                <li>
                                    <strong>Kickoff a new, blank case:</strong> Select Kickoff New Case and then Start New in the navigation bar.
                                </li>
                                <li>
                                    <strong>Kickoff a case for candidate identified through Interfolio Faculty Search:</strong>
                                    <ol type='a'>
                                        <li>
                                            From the Case Dashboard, scroll down through the table of open cases and locate the candidate in question
                                        </li>
                                        <li>
                                            Click on “KICKOFF CASE” button in the “Current Workflow Step” column. <img class="inline-img" src="/help/kickoff_button.png" />
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>Reappoint someone who has taught at Penn recently:</strong>
                                    <ol type='a'>
                                        <li>
                                            Select "Kickoff New Case" and then "From Reappointment List" in the navigation bar
                                        </li>
                                        <li>
                                            In the Reappointments table, scroll down through the table and locate the candidate to reappoint
                                        </li>
                                        <li>
                                            Click on the “KICKOFF CASE” button <img class="inline-img" src="/help/kickoff_button.png" />
                                        </li>
                                        <li>
                                            <strong>Note:</strong> Reappointment actions are only valid for full time lecturers if there is no break in service, and for part time lecturers if they have taught within the last two years.  If you do not see a faculty member listed in the reappointment list and you think you should, please contact the help desk at <a href="mailto:sas-lecturer-help@sas.upenn.edu">sas-lecturer-help@sas.upenn.edu</a>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <strong>LPS Only - Kickoff a case using Course Production System data to pre-populate the candidate and course information:</strong>
                                    <ol type='a'>
                                        <li>
                                            Select "Kickoff New Case" and then "From Course Production System" in the navigation bar
                                        </li>
                                        <li>
                                            In the CPS Quick Entry table, scroll down through the table and locate the course and candidate to reappoint
                                        </li>
                                        <li>
                                            Click on the “KICKOFF CASE” button <img class="inline-img" src="/help/kickoff_button.png" />
                                        </li>
                                        <li>
                                            <strong>Note:</strong> If the person you picked is selected to teach any other courses, you will be given the option to add those courses to the appointment as well, or simply proceed with only the course you have selected.
                                        </li>
                                        <li>
                                            Case Matches shows all cases which match that course and term. You can use this column to verify whether someone has already created a case for that course and term (instructors may not match).
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </p>
                    </div>
                    <div class="md-layout-item md-size-30">
                        <img class="right-column-img" src="/help/start_new.png" />
                    </div>
                </div>

                <md-app-toolbar  id="restartcase" class="md-primary">
                    <span class="md-title">Restart a Saved Case</span>
                </md-app-toolbar>
                <div class="md-layout md-alignment-top-space-between">
                    <div class="md-layout-item md-size-70">
                        <p>
                            Every time a step in the Case Kickoff form is completed (by clicking Continue at the bottom of each step), the form is saved. If you accidentally close your window or browse to another page, you can return to your saved case at anytime by clicking the “Restart Open Case” button in the navigation bar. If you don’t see this button, you don’t have a saved case available to restart.
                        </p>
                    </div>
                    <div class="md-layout-item md-size-30">
                        <img class="right-column-img" src="/help/restart_button.png" />
                    </div>
                </div>

                <md-app-toolbar  id="viewcase" class="md-primary">
                    <span class="md-title">View Case Status and Details</span>
                </md-app-toolbar>
                <div class="md-layout md-alignment-top-space-between">
                    <div class="md-layout-item md-size-100">
                        <p>
                            Once you have created an Interfolio Case through Case Kickoff and sent the case along to the next step in the workflow, it will no longer be visible in your Interfolio queue. To see:
                            <ul>
                                <li>
                                    The status of the case
                                </li>
                                <li>
                                    Limited details about the case (candidate name & email, case type and date created)
                                </li>
                                <li>
                                    What step the case is currently on
                                </li>
                                <li>
                                    Who is currently looking at the case (which committee and who is part of that committee)
                                </li>
                            </ul>
                            go to the Case Dashboard in the top navigation bar. Scroll down to the case you would like to view in more detail and click on the name of the candidate.
                        </p>
                        <img class="inline-img-by-width" src="/help/view_case.png" />
                        <p>
                            Case Detail page options:
                            <ol>
                                <li>
                                    <strong>If you currently have access to the case in Interfolio,</strong> you can go directly to Interfolio by clicking the link in the header
                                    <img class="inline-img-by-width" src="/help/goto_interfolio.png" />
                                </li>
                                <li>
                                    Clicking on any step will expand that step to display the names and contact information for all committee members involved in that step
                                </li>
                                <li>
                                    <img class="inline-img" src="/help/step_completed.png" />
                                    Steps with a check mark have been completed
                                </li>
                                <li>
                                    <img class="inline-img" src="/help/step_active.png" />
                                    The step marked with a pencil is the step the case is currently on
                                </li>
                                <li>
                                    <img class="inline-img" src="/help/step_incomplete.png" />
                                    Steps with an unchecked box have not yet been completed
                                </li>
                                <li>
                                    <img class="inline-img" src="/help/step_warning.png" />
                                    Steps with a red warning have errors in Interfolio. If you see any red errors, please email the help desk at sas-lecturer-help@sas.upenn.edu . This usually occurs when a case is assigned to a committee that has no members.
                                </li>
                            </ol>
                        </p>
                    </div>
                </div>

                <md-app-toolbar  id="form" class="md-primary">
                    <span class="md-title">Filling out the Case Kickoff Form</span>
                </md-app-toolbar>
                <div class="md-layout md-alignment-top-space-between">
                    <div class="md-layout-item md-size-100">
                        <p>
                            <span class="md-subheading">General Notes</span>
                            <ul>
                                <li>
                                    Each time you complete a step (clicking the Continue button at the bottom of each step), your case will be saved. You can return to this case at anytime <strong>as long as you are in the same browser </strong> that you used to begin the case.
                                </li>
                                <li>
                                    You will have the option to review and edit all the information you have entered at the end of the Case Kickoff form
                                </li>
                                <li>
                                    You can navigate to previous steps using either the Back button at the bottom of each step or by clicking on the name of the step at the top of the page. <strong>Do not</strong> click your browser’s back button.
                                </li>
                                <li>
                                    All starred fields are required
                                </li>
                            </ul>
                        </p>
                        <hr />
                        <p>
                            <span class="md-subheading">Step 1: Identify Candidate – You only need to complete one option!</span>
                            <ul>
                                <li>
                                    <strong>If you have any cases available to import from Interfolio Faculty Search,</strong> you will see three options for selecting a candidate. <strong>If you do not have any cases available to import,</strong> you will only see two options.
                                </li>
                            </ul>
                            <img class="inline-img-by-width-sm" src="/help/step_1_choices_3.png" />
                            <img class="inline-img-by-width-sm" src="/help/step_1_choices_2.png" />
                            <ul>
                                <li>
                                    If at any time while completing this step, you would like to use a different option, click the “Clear” button to clear anything you have entered, and click on a different option header to switch options.
                                </li>
                                <li>
                                    <strong>Option #1</strong> - If you selected Interfolio Search, click on the name of the candidate you would like to import from the list and click “Select This Case” (if you do not have cases available to import, this option will be blank)
                                </li>
                                <li>
                                    <strong>Option #2</strong> - If you selected to find the candidate with a Pennkey, begin typing a Pennkey, Penn ID, or first and last name in the search box and select the person from the list that appears. Searching the Penn directory may take a few seconds. Then click “Select This User”
                                </li>
                                <li>
                                    <strong>Option #3</strong> – If the candidate did not come from Interfolio Search and does not have a Pennkey, you can enter some basic contact information for the candidate in Option #3. Only first name, last name, and email are required. Click continue to complete this step.
                                    <ul>
                                        <li>
                                            If you do not provide a Pennkey for a candidate, the system can not generate a Teaching Chronicle
                                        </li>
                                        <li>
                                            If you do not provide a Pennkey for a candidate, the system will double check that the name you entered is not in the Penn directory and offer you possible matches to select from.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                        <hr />
                        <p>
                            <span class="md-subheading">Step 2: Position</span>
                            <ul>
                                <li>
                                    Selecting certain <strong>“Administrating Orgs”</strong> such as Master of Liberal Arts will create an additional <strong>“Division”</strong> field, which will direct the case to the correct Associate Dean for approval. This field is required.
                                </li>
                            </ul>
                            <img class="inline-img-by-width" src="/help/admin_org.png" />
                            <ul>
                                <li>
                                    <strong>Please note:</strong> Reappointment is <strong>only</strong> available for candidates with a Pennkey. If you would like to complete a reappointment but you do not see “Appointment Type” in Step 2, you must return to Step 1 and use Option #2 (finding a candidate with a Pennkey).
                                </li>
                                <li>
                                    <strong>Note to LPS users:</strong> There are two kinds of LPS users and your options at this step will vary depending on your user permissions:
                                    <ul>
                                        <li>
                                            If you have permission to start LPS cases <strong>and</strong> non-LPS cases, you will see a box to check if you would like this case to be an LPS appointment
                                        </li>
                                        <li>
                                            If you only have permission to start LPS cases, you will not see this box and your case will automatically be created as an LPS case
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <img class="inline-img-by-width-sm" src="/help/lps_check.png" />
                            <ul>
                                <li>
                                    <strong>Note for Language Instructor Appointments:</strong> Checking the box “This is a Language Instructor Appointment” will add the necessary approval steps for language courses to the approval workflow, please remember to check this box for relevant appointments, <strong>even if you have already selected Lecturer in Foreign Languages for Proposed Title</strong>
                                </li>
                            </ul>
                        </p>
                        <hr />
                        <p>
                            <span class="md-subheading">Step 3: Salary and Funding</span>
                            <br /><br />
                            Your options will change depending on the <strong>Funding Source</strong> selected:
                            <ul>
                                <li>
                                    <strong>Note for LPS Users:</strong> There are two kinds of LPS users and your options at this step will vary depending on your user permissions:
                                    <ul>
                                        <li>
                                            If you have permission to start LPS cases <strong>and</strong> non-LPS cases, you will see “LPS Funded Position” among your options for <strong>Funding Source</strong>
                                        </li>
                                        <li>
                                            If you only have permission to start LPS cases, you will see “LPS Funded Position” as your <strong>only</strong> option for <strong>Funding Source</strong>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Department Operating Budget</strong> - you must provide an <strong>pproved Budget Amount</strong> and <strong>Fiscal Year</strong>. Your options for fiscal year will be based on the start and end years you provided in the previous step
                                </li>
                                <li>
                                    All other internal Penn options will require a <strong>Budget Fund Code</strong> and <strong>CREF</strong>. If you do not know these numbers, please contact your regional business office. These fields are required. You must use number only, “XXXXXX” will not be accepted
                                </li>
                            </ul>
                        </p>
                        <hr />
                        <p>
                            <span class="md-subheading">Step 4: Proposed Courses</span>
                            <ul>
                                <li>
                                    This step will start with one blank row to enter the first course. To add any additional courses, click the “Add Another Course” button
                                    <img class="inline-img" src="/help/add_course.png" />
                                </li>
                                <li>
                                    To delete a row that was entered in error, click the “Delete” checkbox on the left end of the row, and then click red trash can icon that appears. You can check as many rows as you would like or to delete all rows, check the “Delete All” checkbox in the upper left of the table.
                                </li>
                            </ul>
                            <img class="inline-img-by-width" src="/help/delete_options.png" />
                            <br /><br />
                            <img class="inline-img-by-width" src="/help/confirm_and_delete.png" />
                            <ul>
                                <li>
                                    The terms available in the dropdown are based on the start and end date provided in Step 2
                                </li>
                                <li>
                                    To view previous enrollments for a course, be sure to enter a <strong>Subject Area</strong> and <strong>Course Number</strong> and then click on the menu button in the <strong>Avg. Enrollment</strong> field
                                    <img class="inline-img" src="/help/history_menu.png" />
                                </li>
                            </ul>
                        </p>
                        <hr />
                        <p>
                            <span class="md-subheading">Step 5: Confirm</span>
                            <ul>
                                <li>
                                    You may review all the information you have entered at this step
                                </li>
                                <li>
                                    To edit any information, click on the edit button (the pencil icon) for the step you would like to edit. When you have completed your edits, click “Return to Confirmation” to save your changes
                                </li>
                            </ul>
                            <img class="inline-img-by-width" src="/help/confirm.png" />
                            <ul>
                                <li>
                                    Please be sure to closely read any warnings you see on this screen and correct any errors
                                </li>
                                <li>
                                    The bottom of the confirmation page will list all documentation you will need to upload to Interfolio after you have kicked off this case
                                </li>
                                <li>
                                    To create the case in Interfolio, click “Confirm”
                                </li>
                                <li>
                                    <strong>Clicking Confirm creates the case in Interfolio with the information you have provided. You will be redirected to a progress screen which will show you each step as it is completed and display any errors. This process may take several minutes. Please note that leaving or closing this page will NOT stop the Case Kickoff process.</strong>
                                </li>
                                <li>
                                    After the Case Kickoff in Interfolio has completed, you may view the case in Interfolio by clicking the link provided to upload the rest of the required documents OR you may return to this case later by clicking on the Name of the case candidate in your Case Dashboard
                                </li>
                            </ul>
                        </p>
                    </div>
                    <md-app-toolbar  id="interfolio" class="md-primary">
                        <span class="md-title">Interfolio Help</span>
                    </md-app-toolbar>
                    <div class="md-layout md-alignment-top-space-between">
                        <div class="md-layout-item">
                            <p>
                                <span class="md-subheading">Instructions for using Interfolio Review Promotion and Tenure (RPT)</span>
                            </p>

                            <a target="_blank" href="/docs/Lecturer_System_RPT_for_Dept_Administrators.pdf">
                                <md-button class="md-primary md-raised">
                                    For Department Administrators
                                    <img alt="download pdf" class="download-icon" src="/pdf_icon.png" />
                                </md-button>
                            </a>

                            <a target="_blank" href="/docs/Lecturer_System_RPT_for_Approvers.pdf">
                                <md-button class="md-primary md-raised">
                                    For Approvers
                                    <img alt="download pdf" class="download-icon" src="/pdf_icon.png" />
                                </md-button>
                            </a>
                        </div>
                    </div>
                </div>

            </md-app-content>
        </md-app>
    </div>
</template>

<script>

    export default {
        name: 'help'
    }

</script>

<style scoped>
    .md-drawer {
        width: 20%;
    }
    .md-app-content {
        text-align: left;
    }
    .inline-img {
        max-height: 30px;
    }
    .inline-img-by-width {
        max-width: 75%;
        padding-left: 12%
    }
    .right-column-img {
        padding-top: 30px;
    }
    .inline-img-by-width-sm {
        max-width: 40%;
        padding-left: 12%
    }

    .md-button-content img.download-icon {
        width: 30px
    }

</style>
