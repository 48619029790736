<template>
    <div id="kickoff-complete">
        <md-card>
            <md-card-header v-if="finished">
                <div class="md-display-2">Interfolio Case Kickoff Complete!</div>
            </md-card-header>
            <md-card-header v-else>
                <div class="md-display-1">Generating Interfolio Case...</div>
            </md-card-header>


            <md-card-content>
                <div class="confirm-head">
                    <div class="head-section">
                        <Game></Game>
                    </div>
                    <div v-if="finished" class="head-section">
                        <p>
                            Your case has been created in Interfolio. To continue working on the case:
                        </p>
                        <md-button :href="caseUrl" class="md-raised md-primary">Open in Interfolio</md-button>
                        <p>or</p>
                        <router-link :to="{name: 'caseDetail', params: {caseId: latestKickoff.interfolioId}}">
                            <md-button class="md-raised md-primary">View Case Details</md-button>
                        </router-link>
                    </div>
                    <div v-else class="head-section">
                        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        <p>
                            This may take 1-3 minutes. If this page does not refresh after several minutes, please contact <a href="mailto:sas-lecturer-help@sas.upenn.edu">the SAS Intefolio help desk.</a>
                        </p>
                    </div>
                </div>
                <md-divider class="divider-with-margin"></md-divider>
                <div class="md-display-1">Progress</div>
                <md-list>
                    <md-list-item class="status-check" v-for="(done, status) in latestKickoff.progress" v-bind:key="status">
                        <md-icon v-if="done">check_box</md-icon>
                        <md-icon v-if="!done">check_box_outline_blank</md-icon>
                        <span class="md-list-item-text">{{status}}</span>
                    </md-list-item>
                </md-list>

                <div v-if="latestKickoff.warnings && latestKickoff.warnings.length > 0">
                    The following issues occured during case initialization.  The School Faculty Affairs Office has been alerted.
                </div>
                <md-list class="md-dense">
                    <md-list-item class="kickoff-warning" v-for="warning in latestKickoff.warnings" v-bind:key="warning.message">
                        <md-icon>warning</md-icon>
                        <span class="md-list-item-text">{{warning.message}}</span>
                    </md-list-item>
                </md-list>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
    import Game from './../components/Game';
    export default {
        components: {
            Game
        },
        name: 'KickoffComplete',
        computed: {
          latestKickoff() {
            return this.$store.getters['interfolio/latestCaseKickoff'];
          },
          finished() {
              return this.latestKickoff.finished;
          },
          caseUrl() {
              if(!this.finished) return "";
              let caseId = this.latestKickoff.interfolioId;
              return process.env.VUE_APP_INTERFOLIO_CASE_URL + caseId;
          }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
    .status-check {
        width: 500px;
        margin: 0px auto;
    }
    .kickoff-warning {
        margin: 0px auto;
        background-color: #ffd30d;
    }
    .kickoff-warning div.md-list-item-content,
    .status-check div.md-list-item-content {
        min-height: 20px;
    }
    .divider-with-margin {
        margin: 1.5em 0;
    }
    .confirm-head {
        display: flex;
        flex-flow: column wrap;
        justify-items: center;
    }
    .head-section {
        flex-basis: 50%;
        padding: 3px;
    }
</style>
